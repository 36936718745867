<template lang="pug">
.row.smart-wizard-page
  .d-none.d-lg-flex.col-lg-1
  .col-3
    om-monks(monk="hi-user2" :fluid="true")
  .col-9.col-lg-7.pb-5
    slot(name="beforeHeading")
      om-heading.smart-wizard-page-before-header.mb-4(
        h4
        v-if="beforeHeading"
        v-html="beforeHeading"
      )
    slot(name="heading")
      .h2.smart-wizard-page-header.font-weight-bold.mb-3(v-if="heading" v-html="heading")
    .smart-wizard-page-lead.font-size-1--5s.pb-5(v-if="$slots.lead || lead")
      slot(name="lead")
        .p(v-if="lead" v-html="lead")
    slot(name="actions")
  .d-none.d-lg-flex.col-lg-1
</template>

<script>
  import smartWizardMixin from '@/views/Wizard/smartWizard';

  export default {
    mixins: [smartWizardMixin],
    props: {
      beforeHeading: { type: String, default: null },
      heading: { type: String, default: null },
      lead: { type: String, default: null },
    },
  };
</script>

<style lang="sass">
  .smart-wizard-page
    .brand-monks
      max-width: 200px
    @media screen and (max-width: 767px)
      &-lead
        font-size: 1.2rem!important
        line-height: 2rem
      &-header
        font-size: 2.2rem!important
</style>
